// libs/core/src/utils/mongo/pagination/dto/pagination.dto.ts
export enum SortOrder {
  ASC = 1,
  DESC = -1,
}
export type SortOptions = { [key: string]: SortOrder | undefined };

// libs/core/src/utils/mongo/pagination/dto/pagination.dto.ts
export interface PaginationModel {
  offset?: number;
  limit?: number;
  sort?: SortOptions;
}
