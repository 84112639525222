import { generatePath } from 'react-router-dom';

import {
  DOWNLOAD_INVOICE_API,
  INVOICE_API,
  INVOICE_PROFORMA_API,
} from '@app/config/api-routes.const';
import { HttpStatusEnum } from '@app/config/error-config';

import { AbortablePromise } from '../helper/abort-promise';
import { httpClientService } from '../client/http/http-client.service';
import { PaginatedResponseModel } from '../model/paginated-response.model';
import { InvoiceModel } from '../model/invoice.model';
import { InvoiceResponseModel } from '../model/invoice-response.model';
import { OrderResponseModel } from '../model/order-response.model';
import { OrderModel } from '../model/order.model';
import { mapOrderModelToInvoiceModel } from '../helper/map-order-model-to-invoice-model';
import { ApiError } from '../error/api-error';
import { SortOrder } from '../model/pagination.model';

class InvoicingService {
  getInvoiceById(invoiceId: string): AbortablePromise<InvoiceModel> {
    const [promise, abort] = httpClientService.get<InvoiceResponseModel>(
      INVOICE_API,
      invoiceId
    );

    return [promise.then((response) => new InvoiceModel(response)), abort];
  }

  getInvoicePerPage(
    offset: number,
    limit: number
  ): Promise<PaginatedResponseModel<InvoiceModel>> {
    const [promise] = httpClientService.list<
      PaginatedResponseModel<InvoiceResponseModel>
    >(INVOICE_API, {
      params: {
        offset,
        limit,
        sort: { createdAt: SortOrder.DESC },
      },
    });

    return promise.then(
      (
        response: PaginatedResponseModel<InvoiceResponseModel>
      ): PaginatedResponseModel<InvoiceModel> => ({
        ...response,
        docs: response.docs?.map(
          (data): InvoiceModel => new InvoiceModel(data)
        ),
      })
    );
  }

  getLastThree(): AbortablePromise<InvoiceModel[]> {
    const [invoicePromise, abortController] = httpClientService.list<
      PaginatedResponseModel<InvoiceResponseModel>
    >(INVOICE_API, {
      params: { limit: 3, sort: { createdAt: SortOrder.DESC } },
    });

    return [
      invoicePromise.then(
        (response) => response.docs?.map((data) => new InvoiceModel(data))
      ),
      abortController,
    ];
  }

  getLatest(): AbortablePromise<InvoiceModel | null> {
    const [invoicePromise, abortController] = httpClientService.list<
      PaginatedResponseModel<InvoiceResponseModel>
    >(INVOICE_API, {
      params: { limit: 1, sort: { createdAt: SortOrder.DESC } },
    });

    return [
      invoicePromise.then((response): InvoiceModel | null => {
        const invoiceList = response.docs?.map(
          (data) => new InvoiceModel(data)
        );
        if (invoiceList.length > 0) {
          return invoiceList[0];
        }

        return null;
      }),
      abortController,
    ];
  }

  getProforma(): AbortablePromise<InvoiceModel[]> {
    const [invoicePromise, abortController] =
      httpClientService.list<PaginatedResponseModel<OrderResponseModel>>(
        INVOICE_PROFORMA_API
      );

    return [
      invoicePromise.then(
        (response) =>
          response.docs?.map((data) =>
            mapOrderModelToInvoiceModel(new OrderModel(data))
          )
      ),
      abortController,
    ];
  }

  download(invoiceId: string): Promise<Blob> {
    return this.getOrPostPDF(invoiceId, 'GET').catch((error) => {
      if (
        error instanceof ApiError &&
        error.status === HttpStatusEnum.NOT_FOUND
      ) {
        return this.getOrPostPDF(invoiceId, 'POST');
      }

      throw error;
    });
  }

  getOrPostPDF(invoiceId: string, method: 'GET' | 'POST'): Promise<Blob> {
    const [promise] = httpClientService.run<Blob>(
      generatePath(DOWNLOAD_INVOICE_API, { invoiceId }),
      {
        method,
        responseType: 'blob',
      }
    );

    return promise;
  }
}

export const invoicingService = new InvoicingService();
