import { useCallback, useState } from 'react';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { startRegistration as openWebauthnDialog } from '@simplewebauthn/browser';
import type { RegistrationResponseJSON } from '@simplewebauthn/types';

import { Logger } from '@app/core/logger/logger';
import { ApiError } from '@app/core/error/api-error';
import { setInLocalStorage } from '@app/core/storage/local-storage';
import { LAST_AUTHENTICATION_METHOD_LSK } from '@app/config/localstorage-keys.const';
import { useDeviceName } from '@app/core/helper/use-device-name';
import { AuthenticationMethodEnum } from '@app/core/model/enum/authentication-method.enum';
import { authenticationMethodService } from '@app/core/service/authentication-method.service';
import { WebauthnAuthenticatorModel } from '@app/core/model/authenticator.model';
import { configurationService } from '@app/core/configuration/configuration.service';

interface CreateAuthenticatorReturn {
  pending: boolean;
  onStart: () => void;
}

interface CreateAuthenticatorProps {
  onSuccess: (authenticator: WebauthnAuthenticatorModel) => void;
}

export const useCreateAuthenticator = (
  props: CreateAuthenticatorProps
): CreateAuthenticatorReturn => {
  const { onSuccess } = props;
  const { t } = useTranslation();

  const [pending, setPending] = useState(false);
  const defaultAuthenticatorName = useDeviceName();

  const handleCreateAuthenticator = useCallback(async () => {
    setPending(true);
    const [startWebauthnPromise] =
      authenticationMethodService.startAddWebauthn();

    try {
      const challengeWebauthnResponse = await startWebauthnPromise;

      const registrationResponse: RegistrationResponseJSON =
        await openWebauthnDialog({
          useAutoRegister: false,
          optionsJSON: challengeWebauthnResponse.options,
        });

      const [verifyPromise] = authenticationMethodService.verifyAddWebauthn({
        registrationResponse,
        authenticatorName: defaultAuthenticatorName,
      });

      const authenticator = await verifyPromise;

      setInLocalStorage(
        LAST_AUTHENTICATION_METHOD_LSK,
        AuthenticationMethodEnum.WEBAUTHN.toString()
      );
      toast.success(t('manage-authentication-method.webauthn.enable-success'));

      onSuccess(authenticator);
    } catch (error) {
      Logger.debug(error);

      //  Do nothing if webauthn timed out or user declines the UI
      if (!(error instanceof ApiError)) {
        return;
      }

      if (error.isIntercepted) {
        return;
      }

      toast.error(
        `${t('errors.enable-webauthn', {
          phoneNumber: configurationService.getSupportPhoneNumber(),
        })} [Code: ${error.code}]`,
        {
          toastId: error.code,
        }
      );
    } finally {
      setPending(false);
    }
  }, [t, onSuccess, defaultAuthenticatorName]);

  return {
    pending,
    onStart: () => {
      handleCreateAuthenticator();
    },
  };
};
