import { useTranslation } from 'react-i18next';

import { Button } from '@app/shared/button/button';
import { Icon } from '@app/shared/icon/icon';
import { H2 } from '@app/shared/typography/heading/heading';
import { Text } from '@app/shared/typography/text/text';
import { DialogLayout } from '@app/shared/layout/dialog-layout/dialog-layout';
import { useMemo } from 'react';

interface CheckoutResultViewProps {
  isPaymentSolutionReused?: boolean;
  success: boolean;
  hasAlreadyAPaymentMethod: boolean;
  onContinue: () => void;
}

export const CheckoutResultView = ({
  isPaymentSolutionReused = false,
  success,
  hasAlreadyAPaymentMethod,
  onContinue,
}: CheckoutResultViewProps): JSX.Element => {
  const { t } = useTranslation();

  const captionKey = useMemo(() => {
    if (success) {
      return isPaymentSolutionReused
        ? 'success-payment-solution-reused'
        : 'success';
    }

    return hasAlreadyAPaymentMethod ? 'error-keep-old' : 'error';
  }, [success, isPaymentSolutionReused, hasAlreadyAPaymentMethod]);

  return (
    <DialogLayout style={{ zIndex: 5 }}>
      <DialogLayout.Body>
        <Text color={success ? 'success' : 'error'}>
          <Icon name={success ? 'check' : 'x-close'} />
        </Text>

        <H2>
          {t(`quote.callback-dialog.${success ? 'success' : 'error'}.title`)}
        </H2>

        <Text>{t(`quote.callback-dialog.${captionKey}.caption`)}</Text>
      </DialogLayout.Body>

      <DialogLayout.Footer>
        <Button onClick={onContinue}>
          {t(
            `quote.callback-dialog.${
              success ? 'success' : 'error'
            }.close-button`
          )}
        </Button>
      </DialogLayout.Footer>
    </DialogLayout>
  );
};
