import { useState } from 'react';
import { startRegistration as openRegistrationDialog } from '@simplewebauthn/browser';
import type { RegistrationResponseJSON } from '@simplewebauthn/types';

import { Logger } from '@app/core/logger/logger';
import { ApiError } from '@app/core/error/api-error';
import { ErrorCodeEnum } from '@app/config/error-config';
import { useDialog } from '@app/shared/dialog/use-dialog';
import { WebauthnErrorDialog } from '@app/shared/webauthn-error-dialog/webauthn-error-dialog';
import { WebauthnRegistrationStartResponseModel } from '@app/core/model/registration.model';
import { registrationService } from '@app/core/service/registration.service';
import { RegistrationMethodProps } from '@app/feature/registration/registration-method.props';
import { JWT_TOKEN_WST } from '@app/config/api-routes.const';
import { receiveResponseFromHttpOrWebsocketPromise } from '@app/core/client/receive-response-from-http-or-websocket.promise';
import { useDeviceName } from '@app/core/helper/use-device-name';

import { WebauthnRegistrationView } from './webauthn-registration.view';

interface WebauthnRegistrationControllerProps extends RegistrationMethodProps {
  onSwitchToEmailRegistration: () => void;
}

export const WebauthnRegistrationController = ({
  body,
  onRegister,
  onSwitchToEmailRegistration,
  onEmailAlreadyTaken,
}: WebauthnRegistrationControllerProps): JSX.Element => {
  const { isDialogOpen, openDialog, closeDialog } = useDialog();

  const [pending, setPending] = useState(false);
  const defaultAuthenticatorName = useDeviceName();

  const handleStart = async () => {
    setPending(true);

    const [startWebauthnPromise] = registrationService.startWebauthn({
      emailAddress: body.emailAddress,
      firstName: body.firstName,
      lastName: body.lastName,
    });

    try {
      const {
        pseudo,
        options: registrationOptions,
      }: WebauthnRegistrationStartResponseModel = await startWebauthnPromise;

      const registrationResponse: RegistrationResponseJSON =
        await openRegistrationDialog({
          useAutoRegister: false,
          optionsJSON: registrationOptions,
        });

      const [verifyPromise] = registrationService.verifyWebauthn({
        registrationResponse,
        authenticatorName: defaultAuthenticatorName,
        pseudo,
        ...body,
      });

      const verifyResponse = await receiveResponseFromHttpOrWebsocketPromise(
        verifyPromise,
        JWT_TOKEN_WST
      );

      await onRegister(verifyResponse.jwt, pseudo, body.emailAddress);
    } catch (error) {
      //  Do nothing if webauthn timed out or user declines the UI
      if (!(error instanceof ApiError)) {
        Logger.debug(error);
        return;
      }

      if (error.isIntercepted) {
        return;
      }

      switch (error.code) {
        case ErrorCodeEnum.EMAIL_CONFLICT:
        case ErrorCodeEnum.EMAIL_ALREADY_REGISTERED:
          Logger.debug('Email is already taken');
          onEmailAlreadyTaken();
          break;

        default:
          openDialog();
      }
    } finally {
      setPending(false);
    }
  };

  const handleRetry = async () => {
    closeDialog();
    handleStart();
  };

  return (
    <>
      <WebauthnRegistrationView
        pending={pending}
        onStart={handleStart}
        onUseEmail={onSwitchToEmailRegistration}
      />
      <WebauthnErrorDialog
        onRetry={handleRetry}
        onClose={closeDialog}
        open={isDialogOpen}
      />
    </>
  );
};
